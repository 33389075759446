export const BASE_APP_PATH = '/';

const route = (routePath?: string) => `${BASE_APP_PATH}${routePath}`;

export const routePaths = {
  base: route(''),
  dashboard: route('dashboard'),
  login: route('login'),
  signup: route('signup'),
};
