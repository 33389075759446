import config from 'lib/config';

import { IUser } from 'providers/UserProvider/UserContext.interface';

import { ApiClient } from './api-client';

class Api extends ApiClient {
  getMe() {
    return `/me`;
  }
  updateMe(data: Partial<IUser>) {
    return this.http.post(`/me`, data);
  }
  getTFAStatus(email: string): any {
    return this.http.get(
      `/isUserAuthenticated?code=440A5021tz6p20hP9h2JV3MSaFKK1D7aFT3FFulSrz6WAzFutKVUBQ==&appid=resiliant&email=${email}`,
      {
        headers: {
          Pragma: 'no-cache',
          'Cache-Control': 'no-store, no-cache',
        },
      }
    );
  }
  getPseudoAuth(email: string) {
    return this.http.post(`/authenticateUser`, {
      Status: 'OK',
      TransactionID: 'Anything',
      Email: email,
    });
  }
}

export default new Api({
  baseURL: config.API_BASE_URL,
});
