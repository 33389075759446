import { PropsWithChildren } from 'react';

import {
  PublicLayoutWrapper,
  PublicLayoutWrapperInner,
} from 'components/Layout/LayoutWrapper.styled';
import { PublicFooter } from 'components/molecules/PublicFooter';
import { PublicHeader } from 'components/molecules/PublicHeader';

export const PublicLayout = ({ children }: PropsWithChildren<{}>) => {
  return (
    <PublicLayoutWrapper>
      <PublicHeader />
      <PublicLayoutWrapperInner>{children}</PublicLayoutWrapperInner>
      <PublicFooter />
    </PublicLayoutWrapper>
  );
};
