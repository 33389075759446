import React, { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  ButtonFeedback,
  Checkbox,
  Grid,
  InlineFeedback,
  Input,
  Link,
  Text,
  Title,
} from '@resiliantinc/design-system';

import {
  FormSection,
  SignupFormWrapper,
  TnCWrapper,
} from './SignupForm.styles';

interface IRegistrationForm {
  organisationName: string;
  organisationWebsite: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  country: string;
  postCode: string;

  orgContactFirstName: string;
  orgContactLastName: string;
  orgContactTitle: string;
  orgContactPhone: string;
  orgContactEmail: string;

  primaryAdminFirstName: string;
  primaryAdminLastName: string;
  primaryAdminTitle: string;
  primaryAdminPhone: string;
  primaryAdminEmail: string;

  areTermsAccepted: boolean;
}

const SignupForm = () => {
  const { register, handleSubmit, formState, watch } =
    useForm<IRegistrationForm>({
      shouldUseNativeValidation: true,
    });

  const { t } = useTranslation();

  const areTermsAccepted = watch('areTermsAccepted');

  const onSubmit = useCallback(
    async ({ areTermsAccepted }: IRegistrationForm) => {
      console.log(areTermsAccepted);
      // noop
    },
    []
  );

  return (
    <SignupFormWrapper>
      <Title css={{ pb: '$4' }}>
        {t('Signup:ResiliantID Registration Form')}
      </Title>

      <InlineFeedback
        heading={t(
          "Signup:FIll out the form below and press the Register button at the bottom and you'll\n" +
            'receive an email confirming it was received on our enc'
        )}
      />

      <form onSubmit={handleSubmit(onSubmit)}>
        <FormSection>
          <Input
            label={t('Signup:Organization Name')}
            {...register('organisationName')}
            type="text"
            required
            placeholder={t('Signup:ABC Inc.')}
          />
          <Input
            label={t('Signup:Website URL')}
            {...register('organisationWebsite')}
            type="url"
            required
            placeholder={t('Signup:https://abcinc.com')}
          />

          <Input
            label={t('Signup:Street Address')}
            {...register('address1')}
            type="text"
            required
            placeholder={t('Signup:1122 Beach Avenue')}
          />
          <Input
            {...register('address2')}
            type="text"
            required
            placeholder={t('Signup:Suite 100')}
          />

          <Grid columns={2} css={{ columnGap: '$5' }}>
            <Input
              label={t('Signup:City')}
              {...register('city')}
              type="text"
              required
              placeholder={t('Signup:Miami')}
            />
            <Input
              label={t('Signup:State / Province / Prefecture')}
              {...register('state')}
              type="text"
              required
              placeholder={t('Signup:Florida')}
            />
            <Input
              label={t('Signup:Country')}
              {...register('country')}
              type="text"
              required
              placeholder={t('Signup:USA')}
            />
            <Input
              label={t('Signup:Postal Code')}
              {...register('postCode')}
              type="text"
              required
              placeholder={t('Signup:33330')}
            />
          </Grid>
        </FormSection>

        <FormSection>
          <Text bold>{t('Signup:Organization Contact')}</Text>
          <Grid
            columns={2}
            css={{
              columnGap: '$5',
              mt: '$3',
            }}
          >
            <Input
              label={t('Signup:First Name')}
              {...register('orgContactFirstName')}
              type="text"
              required
              placeholder={t('Signup:John')}
            />
            <Input
              label={t('Signup:Last Name')}
              {...register('orgContactLastName')}
              type="text"
              required
              placeholder={t('Signup:Smith')}
            />
            <Input
              label={t('Signup:Title')}
              {...register('orgContactTitle')}
              type="text"
              required
              placeholder={t('Signup:Chief Executive Officer')}
            />
            <Input
              label={t('Signup:Work Telephone Number')}
              {...register('orgContactPhone')}
              type="text"
              required
              placeholder={t('Signup:+1 000-000-0000')}
            />
          </Grid>
          <Input
            label={t('Signup:Work Email Address')}
            {...register('orgContactEmail')}
            type="email"
            required
            placeholder={t('Signup:jsmith@abcinc.com')}
          />
        </FormSection>

        <FormSection>
          <Text bold>{t('Signup:Primary Administrator')}</Text>
          <InlineFeedback
            css={{ mt: '$3' }}
            heading={t(
              'Signup:This person will be entrusted with full admin permissions for the organization.'
            )}
          />
          <Grid columns={2} css={{ columnGap: '$5', mt: '$3' }}>
            <Input
              label={t('Signup:First Name')}
              {...register('primaryAdminFirstName')}
              type="text"
              required
              placeholder={t('Signup:Elizabeth')}
            />
            <Input
              label={t('Signup:Last Name')}
              {...register('primaryAdminLastName')}
              type="text"
              required
              placeholder={t('Signup:Williams')}
            />
            <Input
              label={t('Signup:Title')}
              {...register('primaryAdminTitle')}
              type="text"
              required
              placeholder={t('Signup:Chief Operations Officer')}
            />
            <Input
              label={t('Signup:Work Telephone Number')}
              {...register('primaryAdminPhone')}
              type="text"
              required
              placeholder={t('Signup:+1 000-000-0000')}
            />
          </Grid>
          <Input
            label={t('Signup:Work Email Address')}
            {...register('primaryAdminEmail')}
            type="email"
            required
            placeholder={t('Signup:ewilliams@abcinc.com')}
          />
        </FormSection>

        <TnCWrapper>
          <Checkbox {...register('areTermsAccepted')} required />
          <Text css={{ ml: '$3' }}>
            {t('Signup:We have reviewed and agreed to the ')}
            <Link href={'https://tnc.com'}>
              {t('Signup:Terms and Conditions')}
            </Link>
          </Text>
        </TnCWrapper>

        <ButtonFeedback
          fullWidth
          isLoading={formState.isSubmitting}
          isSuccess={formState.isSubmitted}
          disabled={!areTermsAccepted}
        >
          Sign Up
        </ButtonFeedback>
      </form>
    </SignupFormWrapper>
  );
};

export { SignupForm };
