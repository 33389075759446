import React from 'react';

import LogoLight from 'assets/logo_dark.png';

import {
  HeaderFooterWrapperInner,
  HeaderFooterWrapperOuter,
} from 'components/Layout/LayoutWrapper.styled';

import { Logo } from './PublicHeader.styles';

const PublicHeader = () => {
  return (
    <HeaderFooterWrapperOuter>
      <HeaderFooterWrapperInner>
        <Logo src={LogoLight} />
      </HeaderFooterWrapperInner>
    </HeaderFooterWrapperOuter>
  );
};

export { PublicHeader };
