import { styled } from '@resiliantinc/design-system';

export const LoginFormWrapper = styled('div', {
  maxWidth: '55rem',
  width: '100%',
  m: 'auto',
  mt: '$6',
  p: '$4',
});

export const FeedbackWrapper = styled('div', {
  mt: '$6',
});
