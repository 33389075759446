import { Navigate, Outlet, RouteObject } from 'react-router-dom';

import { ProtectedRoute } from 'lib/ProtectedRoute';

import { BaseRouterProvider } from 'providers/BaseRouterProvider';
import { GuestRoutesProvider } from 'providers/GuestRoutesProvider';

import DashBoard from 'screens/Dashboard/Dashboard';
import { Login } from 'screens/Login';
import { Signup } from 'screens/Signup';

import { routePaths } from './routePaths';

export const appRoutes: RouteObject[] = [
  {
    path: '*',
    element: <Navigate to={routePaths.base} replace />,
  },

  // Guest routes (unprotected)
  {
    path: routePaths.base,
    element: (
      <GuestRoutesProvider>
        <Outlet />
      </GuestRoutesProvider>
    ),
    children: [
      {
        path: routePaths.login,
        element: <Login />,
      },
      {
        path: routePaths.signup,
        element: <Signup />,
      },
    ],
  },

  // Protected routes
  {
    path: routePaths.base,
    element: <ProtectedRoute component={BaseRouterProvider} />,
    children: [
      {
        index: true,
        element: <Navigate to={routePaths.dashboard} />,
      },
      {
        path: routePaths.dashboard,
        element: <DashBoard />,
      },
    ],
  },
];
