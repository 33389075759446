import { styled } from '@resiliantinc/design-system';

export const SignupFormWrapper = styled('div', {
  maxWidth: '55rem',
  width: '100%',
  m: 'auto',
  my: '$8',
});

export const FormSection = styled('div', {
  mt: '$6',
});

export const TnCWrapper = styled('div', {
  display: 'flex',
  mb: '$6',
});
