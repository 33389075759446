import { FC, Suspense } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { I18nextProvider } from 'react-i18next';
import { BrowserRouter } from 'react-router-dom';
import { SWRConfig } from 'swr';

import { FeedbackRoot } from '@resiliantinc/design-system';

import { FullScreenLoader } from 'components/atoms/FullScreenLoader';
import { GlobalStyles } from 'components/atoms/GlobalStyles';
import { MultiProvider } from 'components/atoms/MultiProvider';

import api from 'lib/api';
import i18n from 'lib/i18';

import { IRootProvider } from './RootProvider.interface';

export const RootProvider: FC<IRootProvider> = ({ children }) => {
  return (
    <>
      <GlobalStyles />
      <FeedbackRoot />
      <HelmetProvider>
        <MultiProvider
          providers={[
            <Suspense fallback={<FullScreenLoader />} />,
            <SWRConfig value={{ fetcher: api.swrFetch.bind(api) }} />,
            <I18nextProvider i18n={i18n} />,
            <BrowserRouter />,
          ]}
        >
          <>
            <Helmet defaultTitle="Resiliant" titleTemplate="%s - Resiliant" />
            {children}
          </>
        </MultiProvider>
      </HelmetProvider>
    </>
  );
};
