import { AnimatePresence } from 'framer-motion';
import { Outlet } from 'react-router-dom';

import { Layout } from 'components/Layout';

import { AuthenticatedRoutesProvider } from 'providers/AuthenticatedRoutesProvider';

export const BaseRouterProvider = () => (
  <AuthenticatedRoutesProvider>
    <Layout>
      <AnimatePresence>
        <Outlet />
      </AnimatePresence>
    </Layout>
  </AuthenticatedRoutesProvider>
);
