import { PropsWithChildren, useCallback, useMemo } from 'react';
import { Navigate } from 'react-router-dom';
import useSWR from 'swr';

import api from 'lib/api';
import { useAuth } from 'lib/auth';

import { UserContext } from './UserContext';
import { IUser } from './UserContext.interface';

export const UserProvider = ({ children }: PropsWithChildren<any>) => {
  const { isAuthenticated } = useAuth();
  const { data: user, mutate } = useSWR(isAuthenticated && api.getMe, {});

  const updateUser = useCallback(
    async (data: Partial<IUser>) => {
      await api.updateMe(data);
      await mutate();
    },
    [mutate]
  );

  const value = useMemo(
    () => ({
      user,
      isAuthenticated,
      updateUser,
    }),
    [user, isAuthenticated, updateUser]
  );

  if (
    !isAuthenticated
    // || (isAuthenticated && !user)
  ) {
    // return <FullScreenLoader data-testid="loading" />;
    return <Navigate replace to="/login" />;
  }

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};
