import Cookies from 'js-cookie';
import { useCallback } from 'react';

export const useAuth = () => {
  const getAccessToken = useCallback(async () => {
    return Cookies.get('access-token');
  }, []);

  const logout = useCallback(async (params: any) => {
    Cookies.remove('access-token');
  }, []);

  const isAuthenticated = !!Cookies.get('access-token');

  return {
    isAuthenticated,
    getAccessToken,
    logout,
  };
};
