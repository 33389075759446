// import { useAtom } from 'jotai';
import { PropsWithChildren, ReactNode } from 'react';

// import { MainMenu } from 'components/MainMenu';
// import { showMainMenuAtom } from './Layout.atoms';
import { LayoutContent } from './LayoutContent.styled';
import { LayoutWrapper, LayoutWrapperInner } from './LayoutWrapper.styled';

const Layout = ({
  children,
  layoutBanner,
}: PropsWithChildren<{ layoutBanner?: ReactNode }>) => {
  // const [showMainMenu] = useAtom(showMainMenuAtom);

  return (
    <LayoutWrapper>
      {layoutBanner}
      <LayoutWrapperInner>
        {/*<MainMenu visible={showMainMenu} />*/}
        <LayoutContent>{children}</LayoutContent>
      </LayoutWrapperInner>
    </LayoutWrapper>
  );
};

export { Layout };
